import React from 'react'

import Layout from '../../components/layout'
import Container from '../../components/container'

import './style.css'

const ServicesPage = () => (
  <Layout>
    <Container>
      <section className="services-page">

        <article className="services-text">
          <p>
            AO Architectural Design provides comprehensive Architectural Services and offers technical expertise that contributes to streamlined, on-time, within-budget projects. Our ability to understand your unique needs and apply our experience with attention to detail to enhance your vision is second to none.
          </p>
        </article>

        <section className="services-lists">
          <section>
            <article>
              <p>Most commonly used services (Architect’s Basic Services) include the following five:</p>
              <ul>
                <li>Schematic design</li>
                <li>Design development</li>
                <li>Construction documents</li>
                <li>Bidding or negotiation</li>
                <li>Construction contract administration</li>
              </ul>
            </article>
            
            <article>
              <p>Additional Services include:</p>
              <ul>
                <li>Programming</li>
                <li>Multiple preliminary designs</li>
                <li>Measured drawings</li>
                <li>Existing facilities surveys</li>
                <li>Site evaluation and analysis</li>
                <li>Feasibility studies </li>
                <li>Zoning and planning assistance</li>
                <li>Architectural Interior design</li>
                <li>On-site project representation</li>
                <li>As-designed record drawings</li>
                <li>As-constructed record drawings</li>
                <li>Tenant-related services</li>
                <li>Architect’s coordination of the Owner’s consultants</li>
                <li>Fast-track design services</li>
                <li>Multiple contracts preparation</li>
                <li>3D Imagery</li>
                <li>Renderings and promotional materials</li>
                <li>Graphic Design</li>
                <li>Models</li>
                <li>Cabinetry elevations</li>
                <li>ADA compliance</li>
                <li>Approval processing with local and regional agencies</li>
                <li>Expert witness</li>
              </ul>
            </article>
          </section>

          <section>
            <article>
              <p>Consultant Services include:</p>
              <ul>  
                <li>Survey</li>
                <li>Geotechnical Engineering</li>
                <li>Geological Engineering</li>
                <li>Structural Engineering</li>
                <li>Civil Engineering</li>
                <li>Mechanical Engineering</li>
                <li>Energy Calculations</li>
                <li>Electrical Engineering</li>
                <li>Landscape Architecture</li>
                <li>Smart Home Design</li>
                <li>Sound Engineering</li>
                <li>Lighting Design</li>
                <li>Solar Energy Design</li>
                <li>Kitchen Design</li>
                <li>Historic Evaluation</li>
                <li>Archaeological Evaluation</li>
                <li>Biological Evaluation</li>
                <li>Foresters Evaluation</li>
                <li>Staking and Netting</li>
              </ul>
            </article>
          </section>
        </section>
      
      </section>
    </Container>
  </Layout>
)

export default ServicesPage
